import * as React from "react"
import type { HeadFC } from "gatsby"
import './index.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { useMediaQuery } from 'react-responsive'
import imagemm from '../images/quinta-filtered.png'
import bandeira from '../images/EJu-T-eWkAA8rcd.jpeg'
import "@fontsource/open-sans"


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';









const IndexPage = () => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })


  return (
    <main >

      <SvgBloob />
      <img src={bandeira} alt="" />

      <header>
        <div>
          <span className="de">de</span>
          <div>
            <span>O BRASIL </span>
            <span>JOELHOS</span>
          </div>

        </div>
      </header>
      <section>
        <div className="div-text">
          <span>
            JEJUM E ORAÇÃO
          </span>
          <span>
            PELO BRASIL
          </span>
        <span className="desc">
          DE 14 DE AGOSTO A 30 DE OUTUBRO
        </span>
        </div>

        <div className="slider">

          <Swiper
            className="Swiperjs"
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={2}
            slidesPerView={1}
            navigation={isDesktopOrLaptop}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            autoplay={{
              delay: 4000
            }}
          >
            <SwiperSlide>
              <div className="slideContent">
                <Slide1 />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slideContent">
                <Slide2 />
              </div>
            </SwiperSlide>
            
          </Swiper>

        </div>
      </section>
      <footer>
        
      </footer>
    </main>
  )
}
function SvgBloob() {
  return (
    <svg id="10015.io" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
      <defs>
        <clipPath id="blob">
          <path fill="#fffff" d="M415,292.5Q385,345,342,382.5Q299,420,233.5,441.5Q168,463,136.5,400.5Q105,338,71.5,289Q38,240,79,196.5Q120,153,156,123Q192,93,244,80.5Q296,68,337,104Q378,140,411.5,190Q445,240,415,292.5Z" />
        </clipPath>
      </defs>
      <image x="0" y="0" width="100%" height="100%" clip-path="url(#blob)" xlinkHref={imagemm} preserveAspectRatio="xMidYMid slice"></image>
    </svg>
  )
}

function Slide2() {
  return (
    <div className="slide2">

      <div className="apelo">
        ESCOLHA SEU DIA E HORÁRIO. JUNTE-SE A NÓS
        NESSE TEMPO DE JEJUM E ORAÇÃO PELA NOSSA NAÇÃO.
      </div>

      <div className="days">
        <div className='day'>
          <span className='yellow'>SEGUNDA-FEIRA:</span>
          <span>GOIÁS</span>
          <span>PERNAMBUCO</span>
          <span>SANTA CATARINA</span>

        </div>
        <div className='day'>
          <span className='yellow'>TERÇA-FEIRA:</span>
          <span>AMAZONAS</span>
          <span>CEARÁ</span>
          <span>DISTRITO FEDERAL</span>
          <span>MATO GROSSO</span>
          <span>RIO GRANDE DO SUL</span>

        </div>
        <div className='day'>
          <span className='yellow'> QUARTA-FEIRA:</span>
          <span>MARANHÃO</span>
          <span>MINAS GERAIS</span>
          <span>PARÁ</span>
          <span>PARANÁ</span>
          <span>RIO DE JANEIRO</span>
          <span>SÃO PAULO</span>
          <span>SERGIPE</span>
        </div>

        <div className='day'>
          <span className='yellow'> QUINTA-FEIRA:</span>
          <span>ESPÍRITO SANTO</span>
          <span>MATO GROSSO DO SUL</span>
          <span>RONDÔNIA</span>
          <span>RORAIMA</span>
          <span>TOCANTINS</span>
        </div>

        <div className='day'>
          <span className='yellow'>  SEXTA-FEIRA:</span>
          <span>ACRE</span>
          <span>ALAGOAS</span>
          <span>AMAPÁ</span>
          <span>BAHIA</span>
          <span>PARAÍBA</span>
          <span>PERNAMBUCO</span>
          <span>PIAUÍ</span>
          <span>RIO GRANDE DO NORTE</span>

        </div>

        <div className='day'>
          <span className='yellow'>SÁBADO E DOMINGO:</span>
          <span>TODOS</span>
        </div>
      </div>
    </div>




  )
}

function Slide1() {
  return (
    <div className="slide1">
      <span>
        INFORMATIVO CONCEPAB FENASP
      </span>
      <p>
        Em <span className="yellow">Assembleia Geral</span> na última<span className='yellow'> quarta-feira, </span>dia 10 de agosto, ficou definido <span className="yellow">as seguintes ações</span> para todos os conselhos estaduais, no período de <span className="yellow">14 de Agosto a 30 de outubro</span>, em prol das <span className="yellow">eleições 2022</span>:
      </p>

      <div>
        <p>
          1. <span className="yellow">ESCALA DE JEJUM E ORAÇÃO</span> POR ESTADO, CLAMANDO E INTERCEDENDO PELO BRASIL, <span className="yellow">DE 14 DE AGOSTO A 30 DE OUTUBRO</span>, DE <span className="yellow">SEGUNDA A SEXTA-FEIRA</span>. AOS <span className="yellow">SÁBADOS E DOMINGOS</span>, TODOS FARÃO O CLAMOR EM SEUS CULTOS.
        </p>
        <p>
          2. CADA CONSELHO ESTADUAL <span className="yellow">ESCOLHERÁ O DIA</span> DA SEMANA QUE <span className="yellow">FICARÁ RESPONSÁVEL</span>, E CONVOCARÁ AS IGREJAS LOCAIS PARA PARTICIPAREM.
        </p>
        <p>
          3. EM <span className="yellow">TODOS OS CULTOS</span>, DURANTE O PERÍODO DO CLAMOR, ORAR PELO PRESIDENTE DA REPÚBLICA, SUA FAMÍLIA E PELAS ELEIÇÕES.
        </p>
        <p>
          4. COLOCAR AS BANDEIRAS <span className="yellow">DO BRASIL E DO SEU RESPECTIVO ESTADO</span> NOS PULPITOS DAS IGREJAS.
        </p>
      </div>
    </div>
  )
}
export default IndexPage

export const Head: HeadFC = () => <title>Brasil de joelhos</title>
